import { graphql } from "gatsby";
// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384
import AuthorPage from "./AuthorPage";
export default AuthorPage;

// ALWAYS keep non-static page queries in the index file (Gatsby requirement)!
// Maybe there is a sense to move MarkdownPage component declaration to the index
// file to keep component, props and query together...
// Or to replace this with a StaticQuery
export const jobOpeningQuery = graphql`
  query AuthorPageQuery($slug: String!, $mdLocale: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, locale: { eq: $mdLocale } }) {
      html
      frontmatter {
        firstName
        lastName
        jobTitle
        avatar
      }
    }
  }
`;

export { default as AuthorTemplate } from "./AuthorTemplate";
