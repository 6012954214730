import memoizeOne from "memoize-one";
import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { MdPageProps } from "@src/types";

import AuthorTemplate from "./AuthorTemplate";

export type JobOpeningPageProps = MdPageProps<{
  markdownRemark: {
    html?: string;
    frontmatter: {
      firstName: string;
      lastName: string;
      jobTitle: string;
      avatar?: string;
    };
  };
}>;

class AuthorPage extends React.PureComponent<JobOpeningPageProps> {
  private extractDescription = memoizeOne((html: string): string => {
    const [description] = html
      // remove HTML tags
      .replace(/<[^>]+>/g, "")
      // get first 160 chars
      .slice(0, 160)
      // match only whole words
      .match(/(^(?:\S+\s+\n?)+)/gm)!;
    return description;
  });

  public render() {
    const {
      markdownRemark: { html, frontmatter },
    } = this.props.data;
    const fullName = `${frontmatter.firstName} ${frontmatter.lastName}`;
    const description = html
      ? this.extractDescription(html)
      : `One of the authors of Trucknet's blog ${fullName}`;

    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={fullName}
          description={description}
          keywords={[fullName, "author", "blog", "Trucknet"]}
        />
        <AuthorTemplate
          firstName={frontmatter.firstName}
          lastName={frontmatter.lastName}
          jobTitle={frontmatter.jobTitle}
          avatar={frontmatter.avatar}
          info={html}
        />
      </IndexLayout>
    );
  }
}

export default AuthorPage;
