import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {},
    header: {
      display: "flex",
    },
    fullName: {},
    avatar: {
      marginRight: theme.spacing(3),
    },
    info: {},
  });
