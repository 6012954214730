import { Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import CloudinaryAvatar from "@src/components/CloudinaryAvatar";
import CmsContent from "@src/components/CmsContent";
import PageLayout from "@src/layouts/PageLayout";

import { styles } from "./AuthorTemplate.styles";

interface Props extends WithStyles<typeof styles> {
  info: React.ReactNode;
  firstName: string;
  lastName: string;
  avatar?: string;
  jobTitle: string;
}

class AuthorTemplate extends React.PureComponent<Props> {
  public render() {
    const { classes, firstName, lastName, avatar, info, jobTitle } = this.props;
    return (
      <PageLayout className={classes.container}>
        <div className={classes.header}>
          <CloudinaryAvatar
            className={classes.avatar}
            src={avatar}
            firstName={firstName}
            lastName={lastName}
          />
          <div>
            <Typography variant="h3" className={classes.fullName}>
              {firstName} {lastName}
            </Typography>
            <Typography variant="subtitle1">{jobTitle}</Typography>
          </div>
        </div>
        <CmsContent content={info} className={classes.info} />
      </PageLayout>
    );
  }
}

export default withStyles(styles)(AuthorTemplate);
